import {useState,useRef,useEffect,useMemo} from 'react';
import {collection,getDocs,query,where,or,orderBy,limit,startAfter} from 'firebase/firestore';
import {db} from '../firebase';
import AddandEdit from '../Components/AddandEdit';
import Product from './Product';
import ImageView from '../Components/ImageView';

const Products=()=>{
  const [add,setAdd]=useState(false);
  const [addStock,setAddStock]=useState(false);
  const [data,setData]=useState(window.doc.products??[]);
  const [imageView,setImageView]=useState(false);
  const [company,setCompany]=useState('rosella');
  const [group,setGroup]=useState('');
  const [group2,setGroup2]=useState('');
  const [search,setSearch]=useState('');
  const more=useRef(false);

  window.setAdd=setAdd;
  window.add=add;
  window.setAddStock=setAddStock;
  window.addStock=addStock;
  window.getProducts=getProducts;

  useEffect(()=>{
    if(!data[0]){
      getProducts();
    }
    else{
      more.current=true
      more.lastDoc=window.productsLastDoc;
    }
    return()=>{
      window.productsLastDoc=more.lastDoc;
    }

  },[])

  function getProducts(){
    const lim=3;
    const conditions=[orderBy('barcode'),limit(lim)];
    const stList=[
      {name:'company',state:company},
      {name:'group',state:group},
      {name:'group2',state:group2}
    ];
    stList.map((st)=>{if(st.state){
      conditions.push(where(st.name,'==',st.state))
    }})

    if(more.current=='more'){
      conditions.push(startAfter(more.lastDoc))
    }

    const q=query(collection(db,'products'),...conditions);
    const qS=query(collection(db,'products'),where('name','>=',search),where('name','<=',search+'~'));
    const qT=search?qS:q;

    window.functions.getDocuments('products',data,setData,qT,more,lim);
  }


  const ProductMemo=useMemo(()=>{
    if(data[0]){
      return data.map((i,index)=>{
        return <Product key={index} product={i} setImageView={setImageView}/>
      })
    }
  },[data])


  return(
    <div style={{width:'100%',height:'100%',justifyContent:'start'}}>
      <div
      onScroll={(e)=>{window.functions.onScroll(e,more,getProducts)}}
      style={{width:'100%',maxWidth:700,display:'block',justifyContent:'start',paddingBottom:200}}>
        <Search
        company={company}
        setCompany={setCompany}
        group={group}
        setGroup={setGroup}
        group2={group2}
        setGroup2={setGroup2}
        search={search}
        setSearch={setSearch}/>
        {ProductMemo}
      </div>
      {add?
      <AddandEdit/>
      :null}
      {imageView?
      <ImageView imageView={imageView} setImageView={setImageView}/>
      :null}
      {window.auth=='admin'?
      <div style={{position:'fixed',flexDirection:'row',bottom:15,right:15}}>
        <button
        onClick={()=>{setAdd({action:'edit'})}}
        style={{width:30,height:30,borderRadius:7,margin:2}}>
          <img
          style={{width:20,height:20}}
          src={require('../Assets/PlusIcon.png')}/>
        </button>
        <button
        onClick={()=>{window.setAdd({action:'category',title:'Kategoriler'})}}
        style={{width:30,height:30,borderRadius:7,margin:2}}>
          <img
          style={{width:20,height:20}}
          src={require('../Assets/CategoryIcon.png')}/>
        </button>
        {window.auth=='admin'?
        <button
        onClick={()=>{window.setNavigate('Stock')}}
        style={{width:30,height:30,borderRadius:7,margin:2}}>
          <img
          style={{width:20,height:20}}
          src={require('../Assets/StockIcon.png')}/>
        </button>
        :null}
      </div>
      :null}
    </div>
  );
}
export default Products;



const Search=({company,setCompany,group,setGroup,group2,setGroup2,search,setSearch})=>{
  const categories=window.doc.infos.categories??null;
  const [showList,setShowList]=useState(false);

  function downloadDocs(){
    const d=new Date();
    const month=(d.getMonth()+1).toString().padStart(2,'0');
    const date=d.getDate().toString().padStart(2,'0')+'-';
    const hours=d.getHours().toString().padStart(2,'0');
    const minutes=d.getMinutes().toString().padStart(2,'0');

    getDocs(collection(db,'products')).then((doc)=>{
      const d=[];
      doc.forEach((item)=>{
        d.push(item.data())
      })
      const a = document.createElement('a');
      a.href = `data:text/plain,${JSON.stringify(d)}`;
      a.download = 'Ürünler '+month+date+hours+minutes+'.txt'
      document.body.appendChild(a);
      a.click();
    })
  }



  return(
    <div style={{width:'100%',paddingBottom:10,paddingTop:10,background:window.color.five,borderRadius:20,marginBottom:5}}>
      <div
      onClick={()=>{setShowList(!showList)}}
      style={{padding:5,flexDirection:'row',width:'90%',justifyContent:'space-between'}}>
        <label style={{}}>
          <div style={{fontSize:20,letterSpacing:2,fontWeight:'bold'}}>
            ROSELLA
          </div>
          <div style={{fontSize:11,letterSpacing:2,marginTop:-5}}>
            Home Consept
          </div>
        </label>
        <img
        onClick={()=>{}}
        style={{width:30,height:30,transform:showList?'rotate(270deg)':'rotate(90deg)'}}
        src={require('../Assets/ArrowIcon.png')}/>
      </div>
        <div style={{width:'100%',display:showList?'flex':'none',paddingTop:20,paddingBottom:10}}>
          <div style={{width:'90%',flexDirection:'row',padding:20,justifyContent:'space-evenly'}}>
            <select
            style={{width:100,textTransform:'capitalize'}}
            onChange={(e)=>{
              setCompany(e.target.value);
              setSearch('');
              setGroup('');
              setGroup2('');
            }}>
            <option value='' disabled selected={company?false:true}>Firma</option>
              {Object.keys(categories)?.map((i)=>{
                return(
                  <option value={i} selected={company==i?true:false}>{i}</option>
                );
              })}
            </select>
            <select
            style={{width:100,textTransform:'capitalize'}}
            disabled={company?false:true}
            onChange={(e)=>{
              setGroup(e.target.value);
              setSearch('');
              setGroup2('');
            }}>
            <option value='' disabled selected={group?false:true}>Sınıf</option>
            <option value=''>Tüm</option>
              {categories[company]?.map((i)=>{
                return(
                  <option value={i.name} selected={group==i.name?true:false}>{i.name}</option>
                );
              })}
            </select>
            <select
            style={{width:100,textTransform:'capitalize'}}
            disabled={group?false:true}
            onChange={(e)=>{
              setGroup2(e.target.value);
              setSearch('');
            }}>
            <option value='' disabled selected={group2?false:true}>Alt Sınıf</option>
            <option value=''>Tüm</option>
              {categories[company]?.find((o)=>(o.name==group))?.kind?.map((i)=>{
                return(
                  <option value={i} selected={group2==i?true:false}>{i}</option>
                );
              })}
            </select>
            <button
            style={{fontSize:12,padding:5}}
            onClick={()=>{window.getProducts()}}>
              Listele
            </button>
          </div>
          <div style={{padding:20,width:'75%',flexDirection:'row'}}>
            <input
            style={{height:20,borderRadius:5}}
            type='text'
            placeHolder='Damla, Zümrüt...'
            value={search}
            onChange={(e)=>{setSearch(e.target.value.toLowerCase())}}/>
            <button
            style={{fontSize:12,marginLeft:'7%',padding:5,paddingLeft:10,paddingRight:10}}
            onClick={()=>{
              setCompany('');
              setGroup('');
              setGroup2('');
              window.getProducts();
            }}>
              Ara
            </button>
          </div>
          {window.dev?
          <label onClick={()=>{downloadDocs()}} style={{alignSelf:'end',paddingRight:20,fontSize:12}}>Verileri Indir</label>
          :null}
        </div>
    </div>
  );
}


// function setTypes(){
//   const categories={
//     rosella:[
//       {name:'koltuk',kind:['takım','üçlü','berjer']},
//       {name:'yatak',kind:['90x190','100x200','120x200','150x200','160x200','180x200']},
//       {name:'baza',kind:['90x190','100x200','120x200','150x200','160x200','180x200']},
//       {name:'başlık',kind:['90x190','100x200','120x200','150x200','160x200','180x200']},
//       {name:'set',kind:['90x190','100x200','120x200','150x200','160x200','180x200']},
//       {name:'kırlent'}
//     ],
//     mavruklar:[
//       {name:'üçlü zigon'},
//       {name:'üçlü c zigon'},
//       {name:'üçlü çitali zigon'},
//       {name:'dörtlü zigon'},
//       {name:'orta sehpa'},
//       {name:'kare yavrulu orta sehpa'},
//       {name:'yan sehpa'},
//       {name:'zigon sehpa'},
//       {name:'yarım ay zigon'},
//       {name:'zigon'}
//     ]
//   }
//   updateDoc(doc(db,'main','infos'),{categories})
// }

// function getAllProducts(){
//   getDocs(query(collection(db,'products'),where('company','==',company))).then(async(doc)=>{
//     const container=[];
//     doc.forEach((item)=>{
//       container.push(item.data())
//     })
//     for(let [index,i] of container.entries()){
//       await changeDoc(i,index)
//     }
//   })
// }
//
// function changeDoc(d,index){
//   return new Promise(function(resolve){
//     updateDoc(doc(db,'products',d.barcode),{
//
//     }).then(()=>{
//       console.log('Doc updated.',index+1);
//       resolve();
//     }).catch((e)=>{
//       console.log('Error update:',e);
//       resolve();
//     })
//   });
// }





// {types.map((i,index)=>{
//   return(
//     <div style={{fontSize:12}}>
//       {i.name}
//       <input
//       style={{accentColor:'black',width:10}}
//       type='checkbox'
//       checked={checked[index]?.check}
//       onChange={(e)=>{setChecked(prev=>{
//         const newPrev=[...prev]
//         newPrev[index]={check:e.target.checked,name:i.name};
//         return newPrev;
//       })}}/>
//     </div>
//   );
// })}
