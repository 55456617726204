import {useState,useEffect} from 'react';
import Sign from './Sign';
import Alert from './Components/Alert';
import Loading from './Components/Loading';
import Question from './Components/Question';
import Products from './Product/Products';
import Stocks from './Stock/Stocks';

const App=()=>{
  const [signed,setSigned]=useState(false);
  const [alert,setAlert]=useState(false);
  const [loading,setLoading]=useState(false);
  const [question,setQuestion]=useState(false);
  const [navigate,setNavigate]=useState('Products');

  window.signed=signed;
  window.setSigned=setSigned;
  window.alert=alert;
  window.setAlert=setAlert;
  window.loading=loading;
  window.setLoading=setLoading;
  window.navigate=navigate;
  window.setNavigate=setNavigate;
  window.question=question;
  window.setQuestion=setQuestion;

  return(
    <div style={{width:'100%',height:'100%'}}>
      {signed=='Signed'?navigate=='Products'?<Products/>:<Stocks/>:<Sign/>}
      {window.alert?
      <Alert/>
      :null}
      {window.loading?
      <Loading/>
      :null}
      {window.question?
      <Question/>
      :null}
    </div>
  );
}

export default App;
