import {getDocs} from 'firebase/firestore';

window.doc={};
window.functions={};
window.color={one:'rgb(0,0,0)',two:'rgb(50,50,50)',three:'rgb(100,100,100)',four:'rgb(150,150,150)',five:'rgb(200,200,200)',six:'rgb(255,255,255)'}
window.dev=process.env.NODE_ENV=='development'?true:false;


window.functions.timeSince=function(millisecond){
  let now=Date.now()-window.timeDif;
  var time;
  if(now-millisecond>3.156e+10){
    if(Math.floor((now-millisecond)/3.156e+10)>1){
      time=Math.floor((now-millisecond)/3.156e+10)+' yıl önce';
    }
    else{
      time=Math.floor((now-millisecond)/3.156e+10)+' yıl önce';
    }
  }
  else if(now-millisecond>2.628e+9){
    const d=new Date(millisecond);
    const nowDate=new Date(now);
    const months = ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"];
    let lastYear=null;
    if(d.getFullYear()!=nowDate.getFullYear()){
      lastYear=d.getFullYear();
    }
    time=months[d.getMonth()]+' '+(lastYear?lastYear:d.getDate())
  }
  else if(now-millisecond>6.048e+8){
    if(Math.floor((now-millisecond)/6.048e+8)>1){
      time=Math.floor((now-millisecond)/6.048e+8)+' '+'hafta önce';
    }
    else{
      time='geçen hafta';
    }
  }
  else if(now-millisecond>8.64e+7){
    if(Math.floor((now-millisecond)/8.64e+7)>1){
      time=Math.floor((now-millisecond)/8.64e+7)+' '+'gün önce';
    }
    else{
      time='dün';
    }
  }
  else if(now-millisecond>3.6e+6){
    if(Math.floor((now-millisecond)/3.6e+6)>1){
      time=Math.floor((now-millisecond)/3.6e+6)+' '+'saat önce';
    }
    else{
      time=Math.floor((now-millisecond)/3.6e+6)+' '+'saat önce';
    }
  }
  //set now if it is not 120000 (2mins).
  else if(now-millisecond>60000){
    time=Math.floor((now-millisecond)/60000)+' '+'dakika önce';
  }
  else if(now-millisecond>0){
    time='şimdi';
  }
    return time;
  }

window.functions.onScroll=function(e,more,getDocuments){
  const scrollHeight=e.target.scrollHeight;
  const scrollTop=e.target.scrollTop;
  const clientHeight=e.target.clientHeight;
  if((scrollHeight-Math.ceil(scrollTop))<=clientHeight){
    if(more.current==true){
      more.current='more';
      getDocuments();
    }
  }
}

window.functions.getDocuments=function(docName,data,setData,qT,more,lim){
  const m=(more.current=='more')?true:false;
  window.setLoading(m?false:true);
  const d=[];
  getDocs(qT).then((doc)=>{
    if(doc.size){
      more.lastDoc=(doc.docs[doc.docs.length-1]);
      doc.forEach((item)=>{
        d.push(item.data())
      });
      if(m){
        setData([...data,...d]);
        window.doc[docName]=window.doc[docName].concat(d);
      }
      else{
        setData(d);
        window.doc[docName]=d;
      }
      more.current=(doc.docs.length<lim)?false:true;
      window.setLoading(false);
    }
    else{
      if(m){
        window.setLoading(false);
      }
      else{
        setData([]);
        window.setLoading(false);
        window.setAlert({message:'Ürün bulunamadı.'});
      }
    }
  })
}
